// eslint-disable-next-line import/prefer-default-export
export const pageLinks = {
  terms: '/terms-of-use',
  privacy: '/privacy-policy',
  cookiePolicy: '/cookies-policy',
  spam: '/spam-policy',
  refund: '/refund-and-cancellation-policy',
  compensation: '/compensation-plan',
  policiesProcedures: '/policies-and-procedures',
  amlPolicy: '/aml-policy/',
  ibi: '/ibi-agreement',
  earningInfo: '/earning-info',
  earningDisclosure: '/be-earning-disclosure-statement',
  codeEthics: '/code-of-ethics',
  eduVideos: '/educational-compliance-videos',
};

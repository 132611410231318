import { useState, useEffect } from 'react';

const useScroll = () => {
  const [isStickyHeader, setStickyHeader] = useState(false);

  const handleScroll = () => {
    const scrolled = window.pageYOffset || document.documentElement.scrollTop;
    if (scrolled > 70) {
      setStickyHeader(true);
    } else if (scrolled < 40) {
      setStickyHeader(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return { isStickyHeader };
};

export default useScroll;

// eslint-disable-next-line import/no-cycle
import {
  COUNTRIES_OF_AFRICA,
  COUNTRIES_OF_AMERICAS,
  COUNTRIES_OF_ASIA_PASIFIC,
  COUNTRIES_OF_EUROPE,
  COUNTRIES_OF_MIDDLE_EAST,
} from '../components/Languages/Languages';

export const spaceToUnderscore = (string) => string.replace(' ', '_');
export const underscoreToSpace = (string) => string.replace('_', ' ');
export const isClient = typeof window === 'object';

export const findCountry = (countryName) => {
  const africa = COUNTRIES_OF_AFRICA.find((c) => c.name === countryName);
  const america = COUNTRIES_OF_AMERICAS.find((c) => c.name === countryName);
  const asiaPas = COUNTRIES_OF_ASIA_PASIFIC.find((c) => c.name === countryName);
  const europe = COUNTRIES_OF_EUROPE.find((c) => c.name === countryName);
  const middleEast = COUNTRIES_OF_MIDDLE_EAST.find((c) => c.name === countryName);

  return africa || america || asiaPas || europe || middleEast;
};

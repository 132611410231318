import React, { useEffect, useState } from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';

import SvgFb from '../assets/images/vectors/social/fb.inline.svg';
import SvgInsta from '../assets/images/vectors/social/insta.inline.svg';
import SvgTwitter from '../assets/images/vectors/social/twitter.inline.svg';
import SvgYoutube from '../assets/images/vectors/social/youtube.inline.svg';
import { pageLinks } from '../helpers/navigation';
import { findCountry, isClient, underscoreToSpace } from '../helpers';
import LanguagesModal from './Languages/LanguagesModal';
// eslint-disable-next-line import/named
import { isBerules } from '../utils/domain';

// const useFooterData = () => {
//   const data = useStaticQuery(
//     graphql`
//       query FooterData {
//         prismic {
//           allFooters {
//             edges {
//               node {
//                 address_title
//                 address_content
//                 link_name_terms_of_use
//                 link_name_gdpr_policy
//                 link_name_ibp_agreement
//                 link_name_policies_procedures
//               }
//             }
//           }
//         }
//       }
//     `
//   );
//   return data;
// };

const policyLinks = [
  {
    name: 'Terms of Use',
    url: pageLinks.terms,
  },
  {
    name: 'Privacy Policy',
    url: pageLinks.privacy,
  },
  {
    name: 'Cookies Policy',
    url: pageLinks.cookiePolicy,
  },
  {
    name: 'Spam Policy',
    url: pageLinks.spam,
  },
  {
    name: 'Refund and Cancellation Policy',
    url: pageLinks.refund,
  },
  {
    name: 'Compensation Plan',
    url: pageLinks.compensation,
  },
  {
    name: 'Policies and Procedures',
    url: pageLinks.policiesProcedures,
  },
  {
    name: 'AML Policy',
    url: pageLinks.amlPolicy,
  },
];

const infoLinks = [
  {
    name: 'IBI Agreement',
    url: pageLinks.ibi,
  },
  // {
  //   name: 'Earning Information',
  //   url: pageLinks.earningInfo,
  // },
  {
    name: 'BE Earning Disclosure Statement',
    url: pageLinks.earningDisclosure,
  },
  {
    name: 'Code Ethics',
    url: pageLinks.codeEthics,
  },
  {
    name: 'Educational Compliance Videos',
    url: pageLinks.eduVideos,
  },
];

const NavLeft = () => (
  <>
    <ul>
      <li>
        <span>Opportunity</span>
      </li>
      <li>
        <Link to="/opportunity/">BE Opportunity</Link>
      </li>
      <li>
        <Link to="/life-at-be/">Life at BE</Link>
      </li>
    </ul>
    <ul>
      <li>
        <span>Products</span>
      </li>
      <li>
        <Link to="/live/">Live</Link>
      </li>
      <li>
        <Link to="/learn/">Learn</Link>
      </li>
    </ul>
    <ul>
      <li>
        <span>About Us</span>
      </li>
      {/* <li> */}
      {/*  <Link to="/our-offices/">Our Offices</Link> */}
      {/* </li> */}
      {/* <li> */}
      {/*  <Link to="/our-story/">Our story</Link> */}
      {/* </li> */}
      <li>
        <Link to="/blog">Blog</Link>
      </li>
      <li>
        <Link to="/contact-us/">Contact Us</Link>
      </li>
    </ul>
  </>
);

// const NavRight = ({ prismicData }) => (
//   <ul>
//     <li>
//       <Link to="/terms-of-use/">{RichText.asText(prismicData.link_name_terms_of_use)}</Link>
//     </li>
//     <li>
//       <Link to="/ibp-agreement/">{RichText.asText(prismicData.link_name_ibp_agreement)}</Link>
//     </li>
//     <li>
//       <Link to="/gdpr-policy/">{RichText.asText(prismicData.link_name_gdpr_policy)}</Link>
//     </li>
//     <li>
//       <Link to="/policies-and-procedures/">
//         {RichText.asText(prismicData.link_name_policies_procedures)}
//       </Link>
//     </li>
//   </ul>
// );
//
// const NavOffices = ({ prismicData }) => (
//   <ul className="temp">
//     <li>
//       <span>{RichText.asText(prismicData.address_title)}</span>
//     </li>
//     <li>{RichText.asText(prismicData.address_content)}</li>
//   </ul>
// );

const NavPolicy = () => (
  <ul>
    {policyLinks.map((link) => (
      <li key={link.url}>
        <Link to={link.url}>{link.name}</Link>
      </li>
    ))}
  </ul>
);

const NavInfo = () => (
  <ul>
    {infoLinks.map((link) => (
      <li key={`_${link.url}`}>
        <Link to={link.url}>{link.name}</Link>
      </li>
    ))}
  </ul>
);

const Footer = () => {
  if (!isClient) {
    return null;
  }
  const [currentLang, setCurrentLang] = useState(null);
  const localStorageFlag = localStorage.getItem('countryFlag');
  const localStorageName = localStorage.getItem('countryName');
  const [isOpenLanguagesModal, setStateLanguagesModal] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const countryParams = urlParams.get('country');

  const changeFlagFromParams = () => {
    if (countryParams) {
      const countryName = underscoreToSpace(countryParams);
      const country = findCountry(countryName);
      setCurrentLang({
        flag: country.flag,
        name: countryName,
      });
    }
  };

  useEffect(() => {
    if (countryParams) {
      localStorage.setItem('countryName', underscoreToSpace(countryParams));
    }

    if (!countryParams && localStorageFlag && localStorageName) {
      setCurrentLang({
        flag: localStorageFlag,
        name: localStorageName,
      });
    }

    if (countryParams) {
      changeFlagFromParams();
    }
  }, []);

  const showLanguagesModal = () => {
    setStateLanguagesModal(true);
  };

  const hideLanguagesModal = () => {
    setStateLanguagesModal(false);
  };

  return (
    <StaticQuery
      query={graphql`
        query {
          prismic {
            allFooters {
              edges {
                node {
                  address_title
                  address_content
                  link_name_terms_of_use
                  link_name_gdpr_policy
                  link_name_ibp_agreement
                  link_name_policies_procedures
                }
              }
            }
          }
        }
      `}
      render={() => {
        // const prismicData = data.prismic.allFooters.edges[0].node;
        return (
          <>
            <footer className="footer">
              <div className="container">
                <div className="row">
                  <div className="col-12 pos-r">
                    <div className="footer__top">
                      <div>
                        <div className="footer__top__left">
                          <NavLeft />
                          <div>
                            <ul className="full-length">
                              <li className="footer__top__legacy">
                                <span>Legal</span>
                              </li>
                            </ul>
                            <div className="footer__top__legacy-container">
                              <NavPolicy />
                              <NavInfo />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/*
                     <div className="footer__top__right">
                      <div className="footer__top__right__offices">
                        <NavOffices prismicData={prismicData} />
                      </div>
                      <div className="footer__top__right__links">
                        <NavRight prismicData={prismicData} />
                      </div>
                     </div>
                     */}
                    </div>
                    <div className="footer__bottom">
                      <div className="footer__bottom__social">
                        <a
                          href="https://www.facebook.com/wearebeofficial/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <SvgFb />
                        </a>
                        <a
                          href="https://www.instagram.com/wearebeofficial_/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <SvgInsta />
                        </a>
                        <a
                          href="https://twitter.com/_beofficial"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <SvgTwitter />
                        </a>
                        <a
                          href="https://www.youtube.com/channel/UCRG8kSqyAH4qhaM0MSECj3g"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <SvgYoutube />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer__languages">
                  {currentLang && (
                    <button
                      className="c-btn-reset footer__languages__wrapper"
                      type="button"
                      onClick={showLanguagesModal}
                    >
                      <img src={currentLang.flag} alt="language" />
                      <span>{currentLang.name}</span>
                    </button>
                  )}
                  <div className="footer__languages__copyright">
                    Berules
                    <sup className="footer__languages__copyright__tm">TM</sup>
                    2022 All rights reserved ⓒ
                  </div>
                </div>
              </div>
            </footer>
            {isOpenLanguagesModal && <LanguagesModal handleClose={hideLanguagesModal} />}
          </>
        );
      }}
    />
  );
};

export default Footer;

import React from 'react';

import Languages from './Languages';

import iconClose from '../../assets/images/vectors/icons/close.svg';

function LanguagesModal({ handleClose }) {
  return (
    <div className="modal">
      <div className="languages-modal">
        <button className="c-btn-reset languages-modal__btn" onClick={handleClose} type="button">
          <img src={iconClose} alt="close" />
        </button>
        <div className="languages-modal__content">
          <Languages />
        </div>
      </div>
    </div>
  );
}

export default LanguagesModal;

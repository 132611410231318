import React, { useState } from 'react';

import iconFlagEgypt from '../../assets/images/vectors/languages/flag_Egypt.png';
import iconFlagMorocco from '../../assets/images/vectors/languages/flag_Morocco.png';
import iconFlagSouthAfrica from '../../assets/images/vectors/languages/flag_South Africa.png';

import iconFlagArgentina from '../../assets/images/vectors/languages/flag_Argentina.png';
import iconFlagBrazil from '../../assets/images/vectors/languages/flag_Brazil.png';
import iconFlagCanada from '../../assets/images/vectors/languages/flag_Canada.png';
import iconFlagChile from '../../assets/images/vectors/languages/flag_Chile.png';
import iconFlagColombia from '../../assets/images/vectors/languages/flag_Colombia.png';
import iconFlagLatinoamérica from '../../assets/images/vectors/languages/flag_Latinoamérica.png';
import iconFlagMéxico from '../../assets/images/vectors/languages/flag_México.png';
import iconFlagPerú from '../../assets/images/vectors/languages/flag_Perú.png';
import iconFlagUnitedStates from '../../assets/images/vectors/languages/flag_United States.png';

import iconFlagAustralia from '../../assets/images/vectors/languages/flag_Australia.png';
import iconFlagChina from '../../assets/images/vectors/languages/flag_China.png';
import iconFlagIndia from '../../assets/images/vectors/languages/flag_India.png';
import iconFlagIndonesia from '../../assets/images/vectors/languages/flag_Indonesia.png';
import iconFlagJapan from '../../assets/images/vectors/languages/flag_Japan.png';
import iconFlagKorea from '../../assets/images/vectors/languages/flag_Korea.png';
import iconFlagMalaysia from '../../assets/images/vectors/languages/flag_Malaysia.png';
import iconFlagNewZealand from '../../assets/images/vectors/languages/flag_New Zealand.png';
import iconFlagPhilippines from '../../assets/images/vectors/languages/flag_Philippines.png';
import iconFlagSingapore from '../../assets/images/vectors/languages/flag_Singapore.png';
import iconFlagThailand from '../../assets/images/vectors/languages/flag_Thailand.png';
import iconFlagVietnam from '../../assets/images/vectors/languages/flag_Vietnam.png';

import iconFlagBaltics from '../../assets/images/vectors/languages/flag_Baltics.png';
import iconFlagBelgique from '../../assets/images/vectors/languages/flag_Belgique.png';
import iconFlagCzechRepublick from '../../assets/images/vectors/languages/flag_Czech Republick.png';
import iconFlagDenmark from '../../assets/images/vectors/languages/flag_Denmark.png';
import iconFlagDeutschland from '../../assets/images/vectors/languages/flag_Deutschland.png';
import iconFlagGreece from '../../assets/images/vectors/languages/flag_Greece.png';
import iconFlagEspana from '../../assets/images/vectors/languages/flag_Espana.png';
import iconFlagFrance from '../../assets/images/vectors/languages/flag_France.png';
import iconFlagItalia from '../../assets/images/vectors/languages/flag_Italia.png';
import iconFlagIreland from '../../assets/images/vectors/languages/flag_Ireland.png';
import iconFlagMagyarország from '../../assets/images/vectors/languages/flag_Magyarország.png';
import iconFlagNederland from '../../assets/images/vectors/languages/flag_Nederland.png';
import iconFlagNorway from '../../assets/images/vectors/languages/flag_Norway.png';
import iconFlagÖsterreich from '../../assets/images/vectors/languages/flag_Österreich.png';
import iconFlagPolska from '../../assets/images/vectors/languages/flag_Polska.png';
import iconFlagPortugal from '../../assets/images/vectors/languages/flag_Portugal.png';
import iconFlagRussia from '../../assets/images/vectors/languages/flag_Russia.png';
import iconFlagTürkiye from '../../assets/images/vectors/languages/flag_Türkiye.png';
import iconFlagSlovakia from '../../assets/images/vectors/languages/flag_Slovakia.png';
import iconFlagSuomi from '../../assets/images/vectors/languages/flag_Suomi.png';
import iconFlagSverige from '../../assets/images/vectors/languages/flag_Sverige.png';
import iconFlagSchweiz from '../../assets/images/vectors/languages/flag_Schweiz.png';
import iconFlagUnitedKingdom from '../../assets/images/vectors/languages/flag_United Kingdom.png';

// import iconFlag from '../../assets/images/vectors/languages';

import iconFlagSaudiArabia from '../../assets/images/vectors/languages/flag_Saudi Arabia.png';
import iconFlagUnitedArabEmirates from '../../assets/images/vectors/languages/flag_United Arab Emirates.png';
import { spaceToUnderscore } from '../../helpers';

export const COUNTRIES_OF_AFRICA = [
  {
    flag: iconFlagEgypt,
    name: 'Egypt',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagMorocco,
    name: 'Morocco',
    lang: 'French',
    code: 'fr',
  },
  {
    flag: iconFlagSouthAfrica,
    name: 'South Africa',
    lang: 'English',
    code: 'en',
  },
];

export const COUNTRIES_OF_AMERICAS = [
  {
    flag: iconFlagArgentina,
    name: 'Argentina',
    lang: 'Spanish',
    code: 'es',
  },
  {
    flag: iconFlagBrazil,
    name: 'Brazil',
    lang: 'Portuguese',
    code: 'pt',
  },
  {
    flag: iconFlagCanada,
    name: 'Canada',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagChile,
    name: 'Chile',
    lang: 'Spanish',
    code: 'es',
  },
  {
    flag: iconFlagColombia,
    name: 'Colombia',
    lang: 'Spanish',
    code: 'es',
  },
  {
    flag: iconFlagLatinoamérica,
    name: 'Latinoamérica',
    lang: 'Spanish',
    code: 'es',
  },
  {
    flag: iconFlagMéxico,
    name: 'México',
    lang: 'Spanish',
    code: 'es',
  },
  {
    flag: iconFlagPerú,
    name: 'Perú',
    lang: 'Spanish',
    code: 'es',
  },
  {
    flag: iconFlagUnitedStates,
    name: 'United States',
    lang: 'English',
    code: 'en',
  },
];

export const COUNTRIES_OF_ASIA_PASIFIC = [
  {
    flag: iconFlagAustralia,
    name: 'Australia',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagChina,
    name: 'China',
    lang: '简体中文',
    code: 'zh',
  },
  {
    flag: iconFlagIndia,
    name: 'India',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagIndonesia,
    name: 'Indonesia',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagJapan,
    name: 'Japan',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagKorea,
    name: 'Korea',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagMalaysia,
    name: 'Malaysia',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagNewZealand,
    name: 'New Zealand',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagPhilippines,
    name: 'Philippines',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagSingapore,
    name: 'Singapore',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagThailand,
    name: 'Thailand',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagVietnam,
    name: 'Vietnam',
    lang: 'English',
    code: 'en',
  },
];
export const COUNTRIES_OF_EUROPE = [
  {
    flag: iconFlagBaltics,
    name: 'Baltics',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagBelgique,
    name: 'Belgique',
    lang: 'French',
    code: 'fr',
  },
  {
    flag: iconFlagCzechRepublick,
    name: 'Czech Republick',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagDenmark,
    name: 'Denmark',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagDeutschland,
    name: 'Deutschland',
    lang: 'English', // change to 'German' when subdomain is up
    code: 'en', // change to 'de' when subdomain is up
  },
  {
    flag: iconFlagGreece,
    name: 'Greece',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagEspana,
    name: 'Espana',
    lang: 'Spanish',
    code: 'es',
  },
  {
    flag: iconFlagFrance,
    name: 'France',
    lang: 'French',
    code: 'fr',
  },
  {
    flag: iconFlagItalia,
    name: 'Italia',
    lang: 'Italian',
    code: 'it',
  },
  {
    flag: iconFlagIreland,
    name: 'Ireland',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagMagyarország,
    name: 'Magyarország',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagNederland,
    name: 'Nederland',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagNorway,
    name: 'Norway',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagÖsterreich,
    name: 'Österreich',
    lang: 'German',
    code: 'de',
  },
  {
    flag: iconFlagPolska,
    name: 'Polska',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagPortugal,
    name: 'Portugal',
    lang: 'Portuguese',
    code: 'pt',
  },
  {
    flag: iconFlagRussia,
    name: 'Russia',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagTürkiye,
    name: 'Türkiye',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagSlovakia,
    name: 'Slovakia',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagSuomi,
    name: 'Suomi',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagSverige,
    name: 'Sverige',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagSchweiz,
    name: 'Schweiz',
    lang: 'French',
    code: 'fr',
  },
  {
    flag: iconFlagUnitedKingdom,
    name: 'United Kingdom',
    lang: 'English',
    code: 'en',
  },
];
export const COUNTRIES_OF_MIDDLE_EAST = [
  {
    flag: iconFlagSaudiArabia,
    name: 'Saudi Arabia',
    lang: 'English',
    code: 'en',
  },
  {
    flag: iconFlagUnitedArabEmirates,
    name: 'United Arab Emirates',
    lang: 'English',
    code: 'en',
  },
];

const COUNTRIES = {
  Africa: [...COUNTRIES_OF_AFRICA],
  Americas: [...COUNTRIES_OF_AMERICAS],
  AsiaPacific: [...COUNTRIES_OF_ASIA_PASIFIC],
  Europe: [...COUNTRIES_OF_EUROPE],
  MiddleEast: [...COUNTRIES_OF_MIDDLE_EAST],
};

const handleLangLink = (code, name, flag) => {
  localStorage.setItem('countryFlag', flag);
  localStorage.setItem('countryName', name);
  const countryQueryName = `?country=${spaceToUnderscore(name)}`;
  const getCode = code === 'en' ? '' : `${code}.`;
  const host =
    window.location.host.split('.')[0].length > 2
      ? window.location.host
      : window.location.host.slice(3);
  const result = `${window.location.protocol}//${getCode}${host}${window.location.pathname}${countryQueryName}`;
  document.location.replace(result);
};

const Languages = () => {
  const [activeTab, setActiveTab] = useState('AsiaPacific');

  return (
    <div className="languages">
      <div className="c-h2">Please choose your region</div>
      <div className="languages__tabs">
        <button
          className={activeTab === 'Africa' ? 'c-btn-reset active' : 'c-btn-reset'}
          onClick={() => setActiveTab('Africa')}
          type="button"
        >
          Africa
        </button>
        <button
          className={activeTab === 'Americas' ? 'c-btn-reset active' : 'c-btn-reset'}
          onClick={() => setActiveTab('Americas')}
          type="button"
        >
          Americas
        </button>
        <button
          className={activeTab === 'AsiaPacific' ? 'c-btn-reset active' : 'c-btn-reset'}
          onClick={() => setActiveTab('AsiaPacific')}
          type="button"
        >
          Asia Pacific
        </button>
        <button
          className={activeTab === 'Europe' ? 'c-btn-reset active' : 'c-btn-reset'}
          onClick={() => setActiveTab('Europe')}
          type="button"
        >
          Europe
        </button>
        <button
          className={activeTab === 'MiddleEast' ? 'c-btn-reset active' : 'c-btn-reset'}
          onClick={() => setActiveTab('MiddleEast')}
          type="button"
        >
          Middle East
        </button>
      </div>
      <div className="languages__list">
        {activeTab &&
          COUNTRIES[activeTab].map((item) => (
            <div
              className="languages__list__country"
              key={item.name}
              onClick={() => handleLangLink(item.code, item.name, item.flag)}
            >
              <div className="languages__list__country__image">
                <img src={item.flag} alt={item.name} />
              </div>
              <div className="languages__list__country__text">
                <div className="languages__list__country__text__name">{item.name}</div>
                <div className="languages__list__country__text__lang">{item.lang}</div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Languages;

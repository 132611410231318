import React, { useEffect, useState } from 'react';
import Flag from 'react-world-flags';
import Select from 'react-select';

// Styles
import '../assets/styles/main.scss';
import { spaceToUnderscore } from '../helpers';
import getLangCode from '../utils/getLangCode';

const getFlag = (name) => <Flag code={name} height="16" />;

export const customStyles = (props) => {
  const styles = (props && props.styles) || {};

  return {
    control: (base, state) => {
      return {
        ...base,
        background: 'transparent',
        borderRadius: state.menuIsOpen ? '3px 3px 0 0' : 3,
        borderColor: '#ffffff',
        boxShadow: 'none',
        width: '70px' || '100%',
        minHeight: 32,
        textTransform: 'uppercase',
        borderBottom: state.menuIsOpen ? '1px solid rgba(162, 126, 181, 0.5)' : '1px solid inherit',
        transition: 'all 0.2s',
        padding: 0,
        '&:hover': {
          cursor: 'pointer',
        },
        div: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          color: styles.color || '#11031c',
        },
        span: {
          display: 'none',
        },
        img: {
          display: 'none',
          width: 21,
          height: 14,
        },
        svg: {
          display: 'none',
          width: '13px',
          height: '12px',
          fill: '#DD98F0',
          transform: 'rotate(180deg)',
        },
      };
    },
    indicatorsContainer: () => ({
      display: 'none !important',
      div: {
        display: 'none',
      },
    }),
    menu: (base) => ({
      ...base,
      background: styles.bg || '#fafafa',
      borderWidth: '0 1px 1px 1px',
      marginTop: 0,
      width: '100%',
      textTransform: 'uppercase',
      color: '#11031c',
      zIndex: 5,

      img: {
        width: 21,
        height: 14,
      },
      span: {
        color: '#11031c',
        fontSize: 12,
        lineHeight: '14px',
        marginLeft: 12,
      },
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      border: 'solid #A27EB5',
      borderWidth: '0 1px 1px 1px',
      borderRadius: '0 0 3px 3px',

      '>div:hover': {
        backgroundColor: 'rgba(255,255,255,0.1)',
        cursor: 'pointer',
        color: '#11031c',
      },
      div: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        height: 32,
        color: '#11031c',
      },
    }),
  };
};

const options = [
  {
    value: 'en',
    countryName: 'United Kingdom',
    label: (
      <div>
        {getFlag('gb')} <span>EN</span>
      </div>
    ),
  },
  // {
  //   value: 'de',
  //   countryName: 'Germany',
  //   label: (
  //     <div>
  //       {getFlag('de')}
  //       {' '}
  //       <span>DE</span>
  //     </div>
  //   ),
  // },
  {
    value: 'zh',
    countryName: 'China',
    label: (
      <div>
        {getFlag('cn')} <span>CN</span>
      </div>
    ),
  },
  {
    value: 'pt',
    countryName: 'Portugal',
    label: (
      <div>
        {getFlag('pt')} <span>PT</span>
      </div>
    ),
  },

  // {
  //   value: 'th',
  //   countryName: 'Thailand',
  //   label: (
  //     <div>
  //       {getFlag('th')}
  //       {' '}
  //       <span>TH</span>
  //     </div>
  //   ),
  // },
  {
    value: 'es',
    countryName: 'Espana',
    label: (
      <div>
        {getFlag('es')} <span>ES</span>
      </div>
    ),
  },
  {
    value: 'fr',
    countryName: 'France',
    label: (
      <div>
        {getFlag('fr')} <span>FR</span>
      </div>
    ),
  },
  {
    value: 'ru',
    countryName: 'Russia',
    label: (
      <div>
        {getFlag('ru')} <span>RU</span>
      </div>
    ),
  },
  {
    value: 'ja',
    countryName: 'Japan',
    label: (
      <div>
        {getFlag('jp')} <span>JA</span>
      </div>
    ),
  },
  {
    value: 'ko',
    countryName: 'Korean',
    label: (
      <div>
        {getFlag('kr')} <span>KO</span>
      </div>
    ),
  },
  // {
  //   value: 'sl',
  //   countryName: 'Slovakia',
  //   label: (
  //     <div>
  //       {getFlag('sl')}
  //       {' '}
  //       <span>SL</span>
  //     </div>
  //   ),
  // },
];

const languages = {
  en: 'en',
  de: 'de',
  zh: 'cn',
  pt: 'pt',
  th: 'th',
  es: 'es',
  fr: 'fr',
  sl: 'sl',
  ru: 'ru',
  ja: 'ja',
  ko: 'ko',
};

const LanguageSelect = () => {
  const localStorageLangCode = localStorage.getItem('countryLangCode');
  const [currentLang, setCurrentLang] = useState('');
  const code = getLangCode();

  useEffect(() => {
    if (code) {
      setCurrentLang(code);
      localStorage.setItem('countryLangCode', code);
    } else {
      setCurrentLang('en');
      localStorage.setItem('countryLangCode', code);
    }
  }, []);

  useEffect(() => {
    if (code && options.some((l) => l.value === code)) {
      setCurrentLang(code);
    }
  }, [code]);

  useEffect(() => {
    if (localStorageLangCode && code && localStorageLangCode !== code) {
      setCurrentLang(code);
    }
  }, []);

  const onLocaleToggle = (props) => {
    const codeCountry = languages[props.value];
    setCurrentLang(props.value);
    localStorage.setItem('countryLangCode', codeCountry);

    const urlParams = new URLSearchParams(window.location.search);
    const countryParams = urlParams.get('country');

    const countryName = localStorage.getItem('countryName');

    const countryQueryName = countryParams
      ? `?country=${spaceToUnderscore(countryParams)}`
      : countryName
      ? `?country=${spaceToUnderscore(countryName)}`
      : `?country='United_Kingdom'`;

    const getCode = codeCountry === 'en' ? '' : codeCountry === 'cn' ? 'zh.' : `${codeCountry}.`;

    const host =
      window.location.host.split('.')[0].length > 2
        ? window.location.host
        : window.location.host.slice(3);
    const result = `${window.location.protocol}//${getCode}${host}${window.location.pathname}${countryQueryName}`;
    document.location.replace(result);
  };

  return (
    <Select
      className="locale-select"
      value={{ label: currentLang }}
      onChange={onLocaleToggle}
      options={options}
      styles={customStyles()}
      isSearchable={false}
      menuPlacement="auto"
      menuPosition="absolute"
      defaultMenuIsOpen={false}
    />
  );
};

export default LanguageSelect;

import React from 'react';

import Header from './Header';
import Footer from './Footer';

// Styles
import '../assets/styles/main.scss';

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div>{children}</div>
      <Footer />
    </>
  );
};

export default Layout;

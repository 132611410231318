const isClient = typeof window === 'object';

// Get lang code from URL (subdomains)
function getLangCode() {
  let langCode = 'en';
  if (isClient) {
    const getFirstUrlElement = window.location.host.split('.')[0];
    const condition =
      getFirstUrlElement === 'berules' ||
      getFirstUrlElement === 'befactor' ||
      getFirstUrlElement === 'be-org' ||
      getFirstUrlElement === 'localhost' ||
      getFirstUrlElement.includes('localhost') ||
      getFirstUrlElement === 'stg' ||
      getFirstUrlElement === 'www';

    if (condition) {
      langCode = 'en';
    } else {
      langCode = getFirstUrlElement;
    }
  }
  return langCode;
}

export default getLangCode;
